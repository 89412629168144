<template>
    <div class="payout">

        <Card>

            <div class="payout__filters">

                <DateRangePicker
                    :label="$lng.__('lk_webmaster_main', 'Period')"
                    ref="picker"
                    opens="right"
                    :locale-data="{
                        firstDay: 1,
                        format: 'dd.mm.yyyy',
                        daysOfWeek: $lng.__('lk_webmaster_main', 'Sun,Mon,Tue,Wed,Thu,Fri,Sat').split(','),
                        monthNames: $lng.__('lk_webmaster_main', 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec').split(','),
                    }"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :showDropdowns="true"
                    :autoApply="true"
                    :dateRange="filters.byDate"
                    v-model="filters.byDate"
                    @update="setDateRange"
                    :linkedCalendars="false"
                >
                </DateRangePicker>

                <FormSelect
                    :label="$lng.__('lk_webmaster_main', 'Status')"
                    :placeholder="$lng.__('lk_webmaster_main', 'Select status')"
                    :tooltip="$lng.__('lk_webmaster_main', 'Filter payouts by status')"
                    :options="optionsStatus"
                    v-model:selected="filters.byStatus"
                ></FormSelect>

                <FormSelect
                    :label="$lng.__('lk_webmaster_main', 'Payment system')"
                    :placeholder="$lng.__('lk_webmaster_main', 'Select payment system')"
                    :tooltip="$lng.__('lk_webmaster_main', 'Filter payouts by payment system')"
                    :options="optionsPaymentSystem"
                    v-model:selected="filters.byPaymentSystem"
                ></FormSelect>

                <div class="payout__filters__reset">
                    <button
                        v-if="isResetFiltersVisible"
                        @click="resetFilters()"
                        class="wv-btn--green wv-btn--text">
                        {{ $lng.__('lk_webmaster_main', 'Reset') }}
                    </button>
                </div>

            </div>

            <div class="card__separator"></div>

            <div
                v-if="!is_loading && !payments.length"
                class="payout__empty">
                {{ $lng.__('lk_webmaster_main', 'No results found.') }}
            </div>

            <div
                v-if="is_loading || payments.length"
                class="payout__table">

                <Loading v-if="is_loading"></Loading>

                <div class="table__wrapper">
                    <table v-if="!is_loading" class="table">
                        <thead>
                            <tr>
                                <th class="text-left">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_payouts', 'Date of formation')"
                                        :isActive="sortBy==='date'"
                                        @sorted="setSortingByDate"
                                    ></SortingIcons>
                                </th>
                                <th class="text-right">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_payouts', 'Money')"
                                        :isActive="sortBy==='money'"
                                        @sorted="setSortingByMoney"
                                    ></SortingIcons>
                                </th>
                                <th>
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_payouts', 'Payment system')"
                                        :isActive="sortBy==='system'"
                                        @sorted="setSortingByPaymentSystem"
                                    ></SortingIcons>
                                </th>
                                <th class="text-center">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_payouts', 'Status')"
                                        :isActive="sortBy==='status'"
                                        @sorted="setSortingByStatus"
                                    ></SortingIcons>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="payments">
                            <tr v-for="payment in paymentsSorted">
                                <td>
                                    {{ payment.timestamp }}
                                </td>
                                <td class="text-right">
                                    {{ payment.money.currency ? payment.money.currency : '&euro;'}} {{ payment.money.amount }}
                                </td>
                                <td class="text-center">
                                    {{ payment.payment_system.name }}
                                </td>
                                <td class="text-center">
                                    {{ $lng.__('lk_webmaster_payouts', payment.status) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </Card>

    </div>
</template>

<script>
export default {
    name: 'Payouts',
    props: [],
    data() {
        return {
            sortBy: 'date',
            sortDirection: 'desc',
            is_loading: true,
            filters: {
                byDate: {
                    startDate: (new Date).addDays(-365),
                    endDate: (new Date)
                },
                byPaymentSystem: false,
                byStatus: false,
            }
        };
    },
    watch: {},
    computed: {
        payments() {
            return this.$store.state.webmaster.payments;
        },
        isResetFiltersVisible() {
            return this.filters.byPaymentSystem
                || this.filters.byStatus;
        },

        // фильтрации
        paymentsFiltered() {
            return this.payments.filter(payment => {
                let pass = true;

                // фильтр по статусу
                if (this.filters.byStatus) {
                    pass = this.filters.byStatus === payment.status;
                }

                // фильтр по платежной системе
                if (pass && this.filters.byPaymentSystem) {
                    pass = this.filters.byPaymentSystem === payment.payment_system.name;
                }

                return pass;
            });
        },

        // сортировки
        paymentsSorted() {
            let sorted = this.paymentsFiltered.sort((a, b) => {
                // сортировка по status asc
                if (this.sortBy === 'status' && this.sortDirection === 'asc') {
                    return (a.status).localeCompare(b.status);
                }

                // сортировка по payment_system asc
                if (this.sortBy === 'system') {
                    let first = (typeof a.payment_system.name === 'object') ? '' : a.payment_system.name;
                    let last = (typeof b.payment_system.name === 'object') ? '' : b.payment_system.name;
                    return first.localeCompare(last);
                }

                // сортировка по money asc
                if (this.sortBy === 'money') {
                    return parseFloat(a.money) - parseFloat(b.money);
                }

                // сортировка по date asc
                if (this.sortBy === 'date') {
                    let first = (typeof a.timestamp === 'object') ? '' : a.timestamp;
                    let last = (typeof b.timestamp === 'object') ? '' : b.timestamp;
                    return first.localeCompare(last);
                }

            });

            let needReverse = this.sortDirection === 'desc';

            return needReverse ? sorted.reverse() : sorted;
        },
        optionsPaymentSystem() {
            return collect(this.payments)
                .pluck('payment_system')
                .pluck('name')
                .unique()
                .whereNotNull()
                .map(name => {
                    return {
                        id: name,
                        name: name
                    };
                })
                .all();
        },
        optionsStatus() {
            return collect(this.payments)
                .pluck('status')
                .unique()
                .whereNotNull()
                .map(status => {
                    return {
                        id: status,
                        name: this.$lng.__('lk_webmaster_payouts', status),
                    };
                })
                .all();
        },
    },
    methods: {
        setSorting(by, direction) {
            this.sortBy = by;
            this.sortDirection = direction;
        },
        setSortingByDate(direction) {
            this.setSorting('date', direction);
        },
        setSortingByMoney(direction) {
            this.setSorting('money', direction);
        },
        setSortingByPaymentSystem(direction) {
            this.setSorting('system', direction);
        },
        setSortingByStatus(direction) {
            this.setSorting('status', direction);
        },
        resetFilters() {
            this.filters = {...this.filters, byPaymentSystem: false, byStatus: false};
        },
        setDateRange(dateRange) {
            this.filters.byDate = dateRange;
            this.is_loading = true;
            this.getPayoutsData();
        },
        getPayoutsData() {
            this.$store.dispatch('webmaster/getPayments', {
                    from: this.filters.byDate.startDate.getFormattedDate() + ' 00:00:00',
                    to: this.filters.byDate.endDate.getFormattedDate() + ' 23:59:59'
                })
                .then(() => {
                    this.is_loading = false;
                });
        },
    },
    beforeMount() {
        this.getPayoutsData();
    }
};
</script>

<style lang="scss">

@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.payout {
    width: 100%;
    margin-top: 90px;
    @include break(md) {
        margin-top: 0;
    }
    &__filters {
        display: flex;
        flex-direction: column;
        margin-top: -30px;
        padding-bottom: 30px;
        @include break(lg) {
            padding-top: 10px;
            flex-direction: row;
        }
        & > * {
            margin-top: 35px;
            @include break(lg) {
                margin-bottom: 0;
                margin-right: 15px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &__reset {
            margin-top: 20px;
            @include break(lg) {
                margin-top: 35px;
            }

        }
    }
    &__table {
        margin: 0 -30px -30px;
    }
    &__empty {
        padding-top: 30px;
        font-weight: 600;
        color: $color-text-muted;
    }
}

</style>
